// OutputPage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import SmartSelectionBox from './SmartSelectionBox/SmartSelectionBox';

function OutputPage() {

  const { srcText } = useParams();
  const sanitizedHTML = srcText || '';

  return (
    <div>    
      <h1>Output Page</h1>
      <SmartSelectionBox srcText={sanitizedHTML} />
    </div>
  );
}

export default OutputPage;