import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import React from 'react';
import InputPage from './pages/InputPage/InputPage';
import OutputPage from './pages/OutputPage/OutputPage';
import Navbar from './components/Navbar/Navbar';

function App() {
  return (
    <Router>
      <Navbar>
        <Routes>
            <Route path="/input" element={<InputPage />} />
            <Route path="/output/:srcText" element={<OutputPage />} />
            <Route path="/output" element={<Navigate to="/input" />} />
            <Route path="/*" element={<Navigate to="/input" />} />
        </Routes>
      </Navbar>
    </Router>
  );
}

export default App;
