// InputPage.js
import React, { useState } from 'react';
import './InputPage.scss';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';

function InputPage() {
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
    
  const handleEditorChange = (state: any) => {
    setEditorState(state);
  };

  const handleNavigate = (srcText: string) => {
      const encodedStrings = encodeURIComponent(srcText);
      navigate(`/output/${encodedStrings}`);
  };

  const getEditorContent = () => {
    const contentState = editorState.getCurrentContent();
    return draftToHtml(convertToRaw(contentState));
  }

  return (
    <div className="input-page-container">
      <h2>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ใส่ข้อความ</h2>
      <div className="editor-container">
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          wrapperClassName="wrapper"
          editorClassName="editor"
        />
      </div>
      <div className="button-container">
        <button className="button" onClick={() => handleNavigate(getEditorContent())}>เรียบร้อย</button>
      </div>
    </div>
    
  ) 
}

export default InputPage;

