import React, { ReactNode }  from 'react';
import { Link } from 'react-router-dom';
import configData from './version.json';
import './Navbar.scss';

interface NavbarProps {
    children: ReactNode;
}

function Navbar ({ children }: NavbarProps) {
  return (
    <div>
      <nav>
        <ul>
            <li>
              <Link to="/" className=''>SMART CAT</Link>
            </li>
            <li className="version">
              v: {configData.WEB_VERSION}
            </li>
            
        </ul>
      </nav>
      {children}

    </div>
  );
}

export default Navbar;
