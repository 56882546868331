import React, { useEffect, useRef, useState } from 'react';
import './SmartSelectionBox.scss';
interface SmartSelectionBoxProps {
  srcText?: string;
}

function SmartSelectionBox(props: SmartSelectionBoxProps) {
  const [srcText, setSrcText] = useState(props.srcText || '');
  const [selectedText, setSelectedText] = useState('');
  const historyRef = useRef<string[]>(['']);
  const textareaRef = useRef(null);
  const oldSelectedTextKey = 'oldSelectedText';

  const isTextareaSelected = () => {
    return document.activeElement === textareaRef.current;
  };

  const saveSelectedText = (value: string) => {
    localStorage.setItem(oldSelectedTextKey, value);
    setSelectedText(value);
  }

  const handleKeyDown = (event: any) => {
    if (event.ctrlKey && isZ(event.key) && !isTextareaSelected() || event.metaKey && isZ(event.key)) {
      console.log('undo');
      handleUndo();
    }
  };

  const isZ = (key: string) => {
    return key === 'z' || key === 'Z';
  }

  useEffect(() => {
    const oldTextData = localStorage.getItem(oldSelectedTextKey);
    if (oldTextData) {
      setSelectedText(oldTextData || '');
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleMouseUp = () => {
    const selection = window?.getSelection()?.toString();
    const newText = selectedText + (selection || '');
    localStorage.setItem(oldSelectedTextKey, newText);
    setSelectedText(newText);
    historyRef.current = [...historyRef.current, newText];
  }

  const handleUndo = () => {
    if (historyRef.current.length > 1) {
      const previousText = historyRef.current[historyRef.current.length - 2];
      console.log(previousText)
      setSelectedText(previousText);
      historyRef.current = historyRef.current.slice(0, -1);
    }
  };

  const saveCurrentText = () => {
    historyRef.current = [...historyRef.current, selectedText];
  }

  return (
    <div style={{ display: 'flex', gap: '20px' }}>

      <div
        className="scroll-container" 
        style={{ overflowY: "scroll", maxHeight: "85vh", flex: 1 }}
      >
        <div 
          className="text-box"
          dangerouslySetInnerHTML={{ __html: srcText}}
          onMouseUp={handleMouseUp}
        />
      </div>
      <textarea
        className='text-box'
        ref={textareaRef}
        style={{ flex: 1 }}
        value={selectedText}
        onChange={(e) => saveSelectedText(e.target.value)}
        onBlur={saveCurrentText}
      />
    </div>
  );
}

export default SmartSelectionBox;